@import "~bootstrap/scss/bootstrap";
@import '~ngx-toastr/toastr-bs4-alert';
@import "~ng-zorro-antd/slider/style/index.css";
/* Import CyberPatient Theme Variables */
@import "./src/assets/css/cyberpatient.scss";

.mat-typography,
.mat-card {
  font-family: "Open Sans" !important;
}

.ng-valid[required],
.ng-valid.required {
  background-image: url("../icons/checkmark.svg") !important;
  background-size: 20px !important;
  background-position: right center;
  background-repeat: no-repeat;
}

.ng-invalid[required].ng-dirty,
.ng-invalid.required.ng-dirty {
  background-image: url("../icons/invalid.svg") !important;
  background-size: 20px !important;
  background-position: right center;
  background-repeat: no-repeat;
}

body {
  font-family: "Open Sans", sans-serif;
  background-color: #eee;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  text-shadow: rgba(0,0,0,.01) 0 0 1px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Open Sans", sans-serif;
}

.action {
  border-radius: 20px !important;
}

h1 {
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: 600;
  color: $cyber-primary;
}

h3 {
  margin-bottom: 20px;
  margin-top: 20px;
  font-size: 18px;
  font-weight: 600;
  color: $cyber-primary;
}

h4 {
  margin-bottom: 20px;
  margin-top: 20px;
  font-size: 18px;
  color: $cyber-primary;
}

a {
  color: $cyber-primary;
}

//
// Buttons
//

.nxtbtn {
  float: right;
}

.bckbtn {
  float: left;
}

.mat-header-cell {
  flex: 1;
  align-self: flex-end;
  color: #706b87 !important;
  font-size: 15px !important;
  padding-bottom: 10px !important;
}

.mat-cell {
  flex: 1;
  align-self: center;
  color: #706b87;
  font-size: 16px !important;
  font-family: "Open Sans", sans-serif;
}

.savebtn {
  float: right;
  margin-top: 10px;
}
::ng-deep .not-mobile {
  background-color: $cyber-warn !important;
  color: White !important;
}
//
// Breadcrumbs
//

.crumbHolder {
  padding: 8px 15px !important;
  margin-bottom: 20px !important;
  list-style: none !important;
  background-color: #f5f5f5 !important;
  border-radius: 4px !important;
  margin-left: 0 !important;
  padding-left: 100px !important;
  position: fixed !important;
  left: 0 !important;
  top: 65px !important;
  width: 100% !important;
  z-index: 699 !important;
}

.breadcrumb {
  display: inline-block;
  width: 100% !important;
  margin: 0 !important;
}

.charticon {
  display: inline-block;
}

.breadcrumb>li {
  display: inline-block;
}

.breadcrumb>li+li:before {
  padding: 0 5px;
  color: #ccc;
  content: "/\00a0";
}

.breadcrumb-item span {
  margin-left: -8px;
}

//
// Material Design Overrides
//
.medication {
  .mat-select-arrow {
    margin: 0 10px;
    margin-top: 20px;
  }

  .mat-form-field-flex {
    padding: 0.5em 0.5em 0.5em 0.5em !important;
  }
}

.chart-body {
  .mat-tab-body {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .mat-tab-body-wrapper {
    height: 100% !important;
  }

  .header h3 {
    text-align: center;
  }
}

.follow {
  width: 150px !important;
  align-self: flex-start;


  .mat-select-value {
    color: #706b87;
  }
}

.filter .mat-form-field-underline {
  display: none;
}

.ivDuration .mat-form-field-underline {
  display: none;
}

.manual-monitoring {
  .mat-form-field-infix {
    box-shadow: none !important;
    padding: 15px !important;
    top: -10px;
  }
}

.output {
  .mat-form-field-infix {
    box-shadow: none !important;
    padding: 15px !important;
    top: -10px;
  }
}

.countries {
  .mat-form-field-infix {
    box-shadow: none !important;
    padding: 15px !important;
    top: -17px;
  }
}

.chip-list-inner {
  .mat-chip-list-wrapper {
    height: 39px;
    min-height: 39px;
    margin-left: 96px !important;
    position: relative;
    top: -10px;

    @media screen and (max-width: 500px) {
      height: 100px;
      max-height: 100px;
      margin-left: 0px !important;
    }
  }
}

.medication .filter input.mat-input-element {
  min-width: 160px;
  height: 30px;
}

.medication {
  .fa {
    padding-right: 5px;
    color: $cyber-primary;
  }

  .mat-chip-list-wrapper {
    position: relative;
    display: block;
    height: 100%;
    overflow-y: visible;
    // DELETED AS THEY WERE CAUSING COPYING ISSUES.
    // height: 190px;
    //  min-height: 190px;
  }

  .filter {

    .mat-form-field-infix {
      width: 80px !important;
      padding-bottom: 0px !important;
    }
  }

  .mat-header-row {
    border-top-color: #ebebeb;
    border-bottom-color: #ebebeb;
    border-top-width: 1px;
  }

  .mat-header-cell.filter {
    flex: 2 !important;
    margin-right: -150px;
  }

  .filter .mat-form-field-infix {
    width: 150px !important;
    // margin: 0;
    // padding: 0;
  }

  .filter mat-form-field {
    width: 100%;
    max-width: 100%;
    margin-top: 10px;
    border-radius: 5px;
    overflow: hidden;
  }

  .filter .mat-form-field-flex,
  .filter .mat-form-field-wrapper {
    height: 100%;
    padding: 0 0.75em !important;
    margin: 5px 0px;
  }

}

.mat-snack-bar-container {
  background-color: white !important;
  color: $cyber-accent !important;
}

.monitoring {
  .filter.mat-header-cell {
    min-width: 150px !important;
    flex: 0;
    padding-bottom: 0px !important;

    @media screen and (max-width: 500px) {
      min-width: auto !important;
    }
  }

  .rate .mat-form-field-wrapper {
    position: relative;
    bottom: -15px;
  }

  mat-cell:first-child {
    flex: 0;

    @media screen and (max-width: 500px) {
      padding-left: 0px;
    }
  }
}

.inputs .mat-chip-list-wrapper {
  height: 130px !important;
  min-height: 130px !important;
}


.units .mat-form-field-infix,
.units .mat-form-field-suffix {
  width: 80px;
  top: -12px;
}

.duration .mat-form-field-infix {
  top: -12px;
}




.follow-up-meds .mat-option-text {
  width: 120px;
}

.mat-select-panel {
  width: 100% !important;
  max-width: 100% !important;
}

/* TURNED OFF BY SARAH
.cdk-overlay-pane {
  overflow-x: hidden !important;
}

.cdk-global-overlay-wrapper {
  background-color: rgba(0, 0, 0, 0.25);
}
*/
.follow-up-option {
  background-color: #fbfafe;
  margin: 3px 0;
  color: #706b87 !important;

  &:hover {
    background-color: $cyber-primary !important;
    color: #fff !important;
  }

  &.mat-selected {
    background-color: #706b87 !important;
    color: #fff !important;
  }
}

.operations {
  mat-label {
    margin-left: 15px;
  }
}

.operations {
  .mat-form-field-infix {
    width: 390px;
    box-shadow: inset 0px 1px 1px 0 rgba(0, 0, 0, 0.32);
    padding-bottom: 0px;
    border-radius: 8px;
    border: 1px solid #fff;

    @media (max-width: 766px) {
      width: 220px;
    }
  }

  .mat-form-field-underline {
    display: none;
  }

  .mat-chip-list-wrapper {
    max-height: 180px;
    display: block;
    overflow-y: auto;
  }

  input.mat-chip-input {
    width: 300px;
    margin: 6px;

    @media (max-width: 766px) {
      width: 220px;
    }
  }
}

.units {
  .mat-form-field-infix {
    width: 100px;
  }

  input {
    width: 80px;
  }
}

.no-indicators .carousel-indicators {
  visibility: hidden;
}

.options {
  .mat-chip {
    background-color: $cyber-primary !important;
    color: #fff !important;
    width: auto !important;
    margin-right: 3px;
  }
}

.operations.mat-focused {
  .mat-form-field-infix {
    border: 1px solid $cyber-primary !important;
  }
}

.follow {
  .mat-select-value {
    min-width: 110px;
  }
}

.mat-chip-list-wrapper {
 // height: 80px;
  min-height: 80px;
  overflow-y: scroll;
  align-items: flex-start !important;

  @media (max-width: 766px) {
    height: 100px;
    min-height: 100px;
  }

  @media (max-width: 1024px) and (min-width: 767px) {
    height: 60px;
    min-height: 60px;
  }
}

.mat-form-field-labelwrapper {
  overflow: visible !important;
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(/assets/vendor/fonts/material.woff2) format('woff2');
}

/* open-sans-300 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url('/assets/vendor/fonts/open-sans-v16-latin-300.eot');
  /* IE9 Compat Modes */
  src: local('Open Sans Light'), local('OpenSans-Light'),
    url('/assets/vendor/fonts/open-sans-v16-latin-300.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('/assets/vendor/fonts/open-sans-v16-latin-300.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('/assets/vendor/fonts/open-sans-v16-latin-300.woff') format('woff'),
    /* Modern Browsers */
    url('/assets/vendor/fonts/open-sans-v16-latin-300.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('/assets/vendor/fonts/open-sans-v16-latin-300.svg#OpenSans') format('svg');
  /* Legacy iOS */
}

/* open-sans-300italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 300;
  src: url('/assets/vendor/fonts/open-sans-v16-latin-300italic.eot');
  /* IE9 Compat Modes */
  src: local('Open Sans Light Italic'), local('OpenSans-LightItalic'),
    url('/assets/vendor/fonts/open-sans-v16-latin-300italic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('/assets/vendor/fonts/open-sans-v16-latin-300italic.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('/assets/vendor/fonts/open-sans-v16-latin-300italic.woff') format('woff'),
    /* Modern Browsers */
    url('/assets/vendor/fonts/open-sans-v16-latin-300italic.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('/assets/vendor/fonts/open-sans-v16-latin-300italic.svg#OpenSans') format('svg');
  /* Legacy iOS */
}

/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/vendor/fonts/open-sans-v16-latin-regular.eot');
  /* IE9 Compat Modes */
  src: local('Open Sans Regular'), local('OpenSans-Regular'),
    url('/assets/vendor/fonts/open-sans-v16-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('/assets/vendor/fonts/open-sans-v16-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('/assets/vendor/fonts/open-sans-v16-latin-regular.woff') format('woff'),
    /* Modern Browsers */
    url('/assets/vendor/fonts/open-sans-v16-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('/assets/vendor/fonts/open-sans-v16-latin-regular.svg#OpenSans') format('svg');
  /* Legacy iOS */
}

/* open-sans-italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  src: url('/assets/vendor/fonts/open-sans-v16-latin-italic.eot');
  /* IE9 Compat Modes */
  src: local('Open Sans Italic'), local('OpenSans-Italic'),
    url('/assets/vendor/fonts/open-sans-v16-latin-italic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('/assets/vendor/fonts/open-sans-v16-latin-italic.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('/assets/vendor/fonts/open-sans-v16-latin-italic.woff') format('woff'),
    /* Modern Browsers */
    url('/assets/vendor/fonts/open-sans-v16-latin-italic.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('/assets/vendor/fonts/open-sans-v16-latin-italic.svg#OpenSans') format('svg');
  /* Legacy iOS */
}

/* open-sans-600 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url('/assets/vendor/fonts/open-sans-v16-latin-600.eot');
  /* IE9 Compat Modes */
  src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
    url('/assets/vendor/fonts/open-sans-v16-latin-600.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('/assets/vendor/fonts/open-sans-v16-latin-600.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('/assets/vendor/fonts/open-sans-v16-latin-600.woff') format('woff'),
    /* Modern Browsers */
    url('/assets/vendor/fonts/open-sans-v16-latin-600.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('/assets/vendor/fonts/open-sans-v16-latin-600.svg#OpenSans') format('svg');
  /* Legacy iOS */
}

/* open-sans-600italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 600;
  src: url('/assets/vendor/fonts/open-sans-v16-latin-600italic.eot');
  /* IE9 Compat Modes */
  src: local('Open Sans SemiBold Italic'), local('OpenSans-SemiBoldItalic'),
    url('/assets/vendor/fonts/open-sans-v16-latin-600italic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('/assets/vendor/fonts/open-sans-v16-latin-600italic.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('/assets/vendor/fonts/open-sans-v16-latin-600italic.woff') format('woff'),
    /* Modern Browsers */
    url('/assets/vendor/fonts/open-sans-v16-latin-600italic.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('/assets/vendor/fonts/open-sans-v16-latin-600italic.svg#OpenSans') format('svg');
  /* Legacy iOS */
}

/* open-sans-700 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url('/assets/vendor/fonts/open-sans-v16-latin-700.eot');
  /* IE9 Compat Modes */
  src: local('Open Sans Bold'), local('OpenSans-Bold'),
    url('/assets/vendor/fonts/open-sans-v16-latin-700.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('/assets/vendor/fonts/open-sans-v16-latin-700.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('/assets/vendor/fonts/open-sans-v16-latin-700.woff') format('woff'),
    /* Modern Browsers */
    url('/assets/vendor/fonts/open-sans-v16-latin-700.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('/assets/vendor/fonts/open-sans-v16-latin-700.svg#OpenSans') format('svg');
  /* Legacy iOS */
}

/* open-sans-700italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 700;
  src: url('/assets/vendor/fonts/open-sans-v16-latin-700italic.eot');
  /* IE9 Compat Modes */
  src: local('Open Sans Bold Italic'), local('OpenSans-BoldItalic'),
    url('/assets/vendor/fonts/open-sans-v16-latin-700italic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('/assets/vendor/fonts/open-sans-v16-latin-700italic.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('/assets/vendor/fonts/open-sans-v16-latin-700italic.woff') format('woff'),
    /* Modern Browsers */
    url('/assets/vendor/fonts/open-sans-v16-latin-700italic.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('/assets/vendor/fonts/open-sans-v16-latin-700italic.svg#OpenSans') format('svg');
  /* Legacy iOS */
}

/* open-sans-800 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  src: url('/assets/vendor/fonts/open-sans-v16-latin-800.eot');
  /* IE9 Compat Modes */
  src: local('Open Sans ExtraBold'), local('OpenSans-ExtraBold'),
    url('/assets/vendor/fonts/open-sans-v16-latin-800.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('/assets/vendor/fonts/open-sans-v16-latin-800.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('/assets/vendor/fonts/open-sans-v16-latin-800.woff') format('woff'),
    /* Modern Browsers */
    url('/assets/vendor/fonts/open-sans-v16-latin-800.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('/assets/vendor/fonts/open-sans-v16-latin-800.svg#OpenSans') format('svg');
  /* Legacy iOS */
}

/* open-sans-800italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 800;
  src: url('/assets/vendor/fonts/open-sans-v16-latin-800italic.eot');
  /* IE9 Compat Modes */
  src: local('Open Sans ExtraBold Italic'), local('OpenSans-ExtraBoldItalic'),
    url('/assets/vendor/fonts/open-sans-v16-latin-800italic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('/assets/vendor/fonts/open-sans-v16-latin-800italic.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('/assets/vendor/fonts/open-sans-v16-latin-800italic.woff') format('woff'),
    /* Modern Browsers */
    url('/assets/vendor/fonts/open-sans-v16-latin-800italic.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('/assets/vendor/fonts/open-sans-v16-latin-800italic.svg#OpenSans') format('svg');
  /* Legacy iOS */
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

.mat-tooltip {
  position: relative;
  padding-bottom: 20px;
  padding: 3px 10px;
  font-size: 14px !important;
  margin: 14px;
  max-width: 250px;
  text-align: center !important;
  overflow: visible !important;
  border: 1px solid #FFFFFF;
  border-radius: 8px;
  background-color: #F8F8F8 !important;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);
  color: #706B87 ! important;
}

.mat-tooltip::after {
  content: "";
  position: fixed;
  left: 50%;
  top: 100%;
  margin-left: -5px;
  text-align: center;
  width: 10px;
  height: 10px;
  border-left: 1px solid #FFF;
  border-bottom: 1px solid #FFF;
  background-color: #F8F8F8 !important;
  clear: both;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  box-shadow: -3px 3px 3px 0 rgba(0, 0, 0, 0.2);
  box-shadow: -3px 3px 3px 0 rgba(0, 0, 0, 0.2);
}

.sidebar.mat-tooltip::after {
  content: "";
  position: fixed;
  left: 4px;
  top: 30%;
  text-align: center;
  width: 10px;
  height: 10px;
  border-left: 1px solid #FFF;
  border-bottom: 1px solid #FFF;
  background-color: #F8F8F8 !important;
  clear: both;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-transform: rotate(-45deg);
  transform: rotate(45deg);
  /* box-shadow: none; */
  box-shadow: -1px 2px 2px 0px rgba(0, 0, 0, 0.2);
}


.mat-tab-label.mat-tab-disabled,
.mat-tab-link.mat-tab-disabled {
  color: $cyber-primary !important;
}

.mat-tab-body.mat-tab-body-active {
  height: 100%;
}

mat-tab-group {
  background-color: white;
  margin-bottom: 40px;
}

/*.mat-tab-body.mat-tab-body-active {
  overflow-y: scroll !important;
}*/
.mat-button.mat-raised-button.mat-primary {
  color: white;
}

.mat-autocomplete-panel {
  min-width: 300px !important;
  width: 385px !important;

  @media (max-width: 766px) {
    min-width: 220px !important;
    width: 220px !important;
  }
}

.mat-form-field-label {
  padding-top: 0.5em !important;
}

.mat-tab-body {
  padding: 10px;
}

.mat-dialog-container {
  border-radius: 8px !important;
  padding: 0px !important;
  min-width: 589px;
}

@media (max-width: 767px) {

  .mat-checkbox-layout,
  .mat-radio-layout,
  .mat-radio-label {
    white-space: normal !important;
  }

  .mat-checkbox-label,
  .mat-radio-label {
    width: 200px !important;
  }
}

.mat-slide-toggle.mat-primary.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
  border: 2px solid $cyber-primary !important;
  background-color: #fff !important;
}

.mat-slide-toggle.mat-primary.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: $cyber-primary !important;
}

.input .mat-slide-toggle-thumb {
  border: 2px solid #8781a4;
  background-color: #fff;
}

.input .mat-slide-toggle-bar {
  background-color: #8781a4;
}

.mech-options .mat-radio-label {
  white-space: normal;
}

.expansion-panel__content {
  margin: auto !important;
}

.mat-tab-body-wrapper {
  //  overflow: scroll !important;
  display: block !important;
  // height: 500px !important;
}

// ^ SARAH TURNED THIS OFF BECAUSE IT'S BAD PRACTICE
.patient-history {
  .mat-tab-body-wrapper {
    //  overflow: scroll !important;
    padding-bottom: 20px;
    height: 100% !important;
  }

  .mat-tab-group {
    display: initial;
  }

  .mat-tab-label-active {
    background-color: rgba(224, 242, 201, 0.3);
  }
}

@media screen and (min-width: 480px) {
  .mat-tab-body {
    padding-left: 50px;
    padding-right: 50px;
  }

  .patient-history .mat-tab-body {
    padding: 0;
  }
}

.mat-radio-label-content,
.mat-checkbox-label {
  color: #706b87 !important;
  font-size: 16px !important;
}

.mat-radio-button .mat-radio-inner-circle {
  display: none;
}

.mat-radio-button.mat-radio-checked .mat-radio-outer-circle {
  border-color: $cyber-primary !important;
  border-width: 6px;
}

.mat-radio-button.mat-radio-outer-circle {
  border-color: #706b87 !important;
}

.mat-checkbox-frame {
  border-color: #706b87 !important;
  border-radius: 4px !important;
}

.mat-checkbox-checked .mat-checkbox-background {
  background-color: $cyber-primary !important;
  border: 2px solid #84b642;
  border-radius: 4px;
}

.mat-checkbox-layout {
  padding: 5px 0px;
}

.mat-checkbox-inner-container:hover .mat-checkbox-frame {
  border: 2px solid $cyber-primary !important;
}

.mat-form-field-hide-placeholder {
  &::placeholder {
    -webkit-text-fill-color: $cyber-primary !important;
    color: $cyber-primary !important;
  }
}

::placeholder {
  color: $cyber-primary !important;
}

.dashcard .mat-fab {
  width: 200px !important;
  height: 200px !important;
  background-color: $cyber-primary;
}

.dashcard .plusicon .material-icons {
  font-size: 80px;
  font-weight: 600;
}

.dashcard .mat-fab .mat-icon,
.mat-fab i {
  /* padding: 50px 50px; */
  font-size: 148px;
  line-height: 24px;
  margin-right: 60%;
  margin-top: 30%;
}

.mat-slider-thumb {
  background-color: #fff;
}

.mat-slider-vertical .mat-slider-thumb {
  background-color: #fff !important;
  overflow: hidden !important;
  border-color: $cyber-primary !important;
  border-width: 2px !important;
  transform: scale(1.15) !important;
}

.mat-slider-track-wrapper {
  border-radius: 9999px;
}

.mat-slider-track-background {
  background-color: #8781a4 !important;
}

.mat-slider-track-fill {
  background-color: $cyber-primary !important;
}

.chart-body {
  .mat-tab-group {
    max-width: 840px;
    //  height: 100%;
  }

  .mat-table {
    min-height: 300px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .mat-tab-labels {
    justify-content: space-around;
    width: 95%;
    margin: 0 auto;
  }

  .mat-tab-label-container {
    background-color: #ebebeb !important;
  }

  .mat-tab-label {
    height: 48px;
    padding: 0 24px;
    cursor: pointer;
    box-sizing: border-box;
    color: #777198;
    font-weight: 300;
    opacity: 1;
    min-width: 100px;
    text-align: center;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    position: relative;
    background-color: #fff;
    margin: 0px 3px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border: 1px solid #ebebeb;
    font-family: "Open Sans", sans-serif;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
    border-bottom: none;
  }

  .mat-tab-label-active {
    font-weight: 600;
    background-color: #fbfafe;
  }

  .mat-tab-group.mat-primary .mat-tab-label:not(.mat-tab-disabled):focus {
    background-color: #fbfafe;
  }

  .mat-ink-bar {
    visibility: hidden !important;
  }
}

.results {
  .mat-table {
    min-height: 300px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .mat-tab-labels {
    justify-content: space-around;
    width: 95%;
    margin: 0 auto;
  }

  .mat-tab-label-container {
    background-color: #ebebeb !important;
  }

  .mat-tab-label {
    height: 48px;
    padding: 0 24px;
    cursor: pointer;
    box-sizing: border-box;
    color: #777198;
    font-weight: 300;
    opacity: 1;
    min-width: 150px;
    text-align: center;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    position: relative;
    background-color: #fff;
    margin: 0px 3px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border: 1px solid #ebebeb;
    font-family: "Open Sans", sans-serif;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
    border-bottom: none;
  }

  .mat-tab-label-active {
    font-weight: 600;
    background-color: #fbfafe;
  }

  .mat-tab-group.mat-primary .mat-tab-label:not(.mat-tab-disabled):focus {
    background-color: #fbfafe;
  }

  .mat-ink-bar {
    visibility: hidden !important;
  }

  .mat-tab-body {
    margin: 0px !important;
    padding: 0px !important;
  }

  .mat-tab-body.mat-tab-body-active {
    overflow-y: hidden !important;
  }

  .mat-header-row {
    padding: 0px 60px;
    border-bottom: none;
  }

  .mat-tab-body-wrapper {
    display: block !important;
    height: 400px !important;
  }

  .mat-row {
    margin: 15px 45px;
  }

  .mat-header-cell {
    padding-top: 10px !important;
    padding-bottom: 0px !important;
  }
}

///MULTIPLE SELECT STUFF
/* Style when highlighting a search. */
.ui-select-highlight {
  font-weight: bold;
}

.ui-select-offscreen {
  clip: rect(0 0 0 0) !important;
  width: 1px !important;
  height: 1px !important;
  border: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden !important;
  position: absolute !important;
  outline: 0 !important;
  left: 0 !important;
  top: 0 !important;
}

.banner-icon {
  float: left;
  margin-right: 15px;
}

.ui-select-choices-row:hover {
  background-color: #f5f5f5;
}

/* Select2 theme */
/* Mark invalid Select2 */
.ng-dirty.ng-invalid>a.select2-choice {
  border-color: #d44950;
}

.select2-result-single {
  padding-left: 0;
}

.select2-locked>.select2-search-choice-close {
  display: none;
}

.select-locked>.ui-select-match-close {
  display: none;
}

body>.select2-container.open {
  z-index: 9999;
  /* The z-index Select2 applies to the select2-drop */
}

/* Handle up direction Select2 */
.ui-select-container[theme="select2"].direction-up .ui-select-match {
  border-radius: 4px;
  /* FIXME hardcoded value :-/ */
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.ui-select-container[theme="select2"].direction-up .ui-select-dropdown {
  border-radius: 4px;
  /* FIXME hardcoded value :-/ */
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-width: 1px;
  /* FIXME hardcoded value :-/ */
  border-top-style: solid;
  box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.25);
  margin-top: -4px;
  /* FIXME hardcoded value :-/ */
}

.ui-select-container[theme="select2"].direction-up .ui-select-dropdown .select2-search {
  margin-top: 4px;
  /* FIXME hardcoded value :-/ */
}

.ui-select-container[theme="select2"].direction-up.select2-dropdown-open .ui-select-match {
  border-bottom-color: #5897fb;
}

/* Selectize theme */
/* Helper class to show styles when focus */
.selectize-input.selectize-focus {
  border-color: #007fbb !important;
}

/* Fix input width for Selectize theme */
.selectize-control>.selectize-input>input {
  width: 100%;
}

/* Fix dropdown width for Selectize theme */
.selectize-control>.selectize-dropdown {
  width: 100%;
}

/* Mark invalid Selectize */
.ng-dirty.ng-invalid>div.selectize-input {
  border-color: #d44950;
}

/* Handle up direction Selectize */
.ui-select-container[theme="selectize"].direction-up .ui-select-dropdown {
  box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.25);
  margin-top: -2px;
  /* FIXME hardcoded value :-/ */
}

/* Bootstrap theme */
/* Helper class to show styles when focus */
.btn-default-focus {
  color: #333;
  background-color: #ebebeb;
  border-color: #adadad;
  text-decoration: none;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
}

.ui-select-bootstrap .ui-select-toggle {
  position: relative;
}

.ui-select-bootstrap .ui-select-toggle>.caret {
  position: absolute;
  height: 10px;
  top: 50%;
  right: 10px;
  margin-top: -2px;
}

/* Fix Bootstrap dropdown position when inside a input-group */
.input-group>.ui-select-bootstrap.dropdown {
  /* Instead of relative */
  position: static;
}

.input-group>.ui-select-bootstrap>input.ui-select-search.form-control {
  border-radius: 4px;
  /* FIXME hardcoded value :-/ */
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group>.ui-select-bootstrap>input.ui-select-search.form-control.direction-up {
  border-radius: 4px !important;
  /* FIXME hardcoded value :-/ */
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.ui-select-bootstrap>.ui-select-match>.btn {
  /* Instead of center because of .btn */
  text-align: left !important;
}

.ui-select-bootstrap>.ui-select-match>.caret {
  position: absolute;
  top: 45%;
  right: 15px;
}

.ui-disabled {
  background-color: #eceeef;
  border-radius: 4px;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 5;
  opacity: 0.6;
  top: 0;
  left: 0;
  cursor: not-allowed;
}

/* See Scrollable Menu with Bootstrap 3 http://stackoverflow.com/questions/19227496 */
.ui-select-bootstrap>.ui-select-choices {
  width: 100%;
  height: auto;
  max-height: 200px;
  overflow-x: hidden;
  margin-top: -1px;
}

body>.ui-select-bootstrap.open {
  z-index: 1000;
  /* Standard Bootstrap dropdown z-index */
}

.ui-select-multiple.ui-select-bootstrap {
  height: auto;
  padding: 3px 3px 0;
}

.ui-select-multiple.ui-select-bootstrap input.ui-select-search {
  background-color: transparent !important;
  /* To prevent double background when disabled */
  height: 1.666666em;
  margin-bottom: 3px;
  border: 1px solid $cyber-primary;
}

.ui-select-multiple.ui-select-bootstrap .ui-select-match .close {
  font-size: 1.6em;
  line-height: 0.75;
}

.ui-select-multiple.ui-select-bootstrap .ui-select-match-item {
  outline: 0;
  margin: 0 3px 3px 0;
}

.ui-select-multiple .ui-select-match-item {
  position: relative;
}

.ui-select-multiple .ui-select-match-item.dropping-before:before {
  content: "";
  position: absolute;
  top: 0;
  right: 100%;
  height: 100%;
  margin-right: 2px;
  border-left: 1px solid #428bca;
}

.ui-select-multiple .ui-select-match-item.dropping-after:after {
  content: "";
  position: absolute;
  top: 0;
  left: 100%;
  height: 100%;
  margin-left: 2px;
  border-right: 1px solid #428bca;
}

.ui-select-bootstrap .ui-select-choices-row>a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;
}

.ui-select-bootstrap .ui-select-choices-row>a:focus,
.ui-select-bootstrap .ui-select-choices-row>a:hover {
  text-decoration: none;
  color: #262626;
  background-color: #f5f5f5;
}

.ui-select-bootstrap .ui-select-choices-row.active>a {
  color: #fff;
  text-decoration: none;
  outline: 0;
  background-color: #428bca;
}

.ui-select-bootstrap .ui-select-choices-row.active.disabled>a,
.ui-select-bootstrap .ui-select-choices-row.disabled>a {
  color: #777;
  cursor: not-allowed;
  background-color: #fff;
}

/* fix hide/show angular animation */
.ui-select-match.ng-hide-add,
.ui-select-search.ng-hide-add {
  display: none !important;
}

/* Mark invalid Bootstrap */
.ui-select-bootstrap.ng-dirty.ng-invalid>button.btn.ui-select-match {
  border-color: #d44950;
}

/* Handle up direction Bootstrap */
.ui-select-container[theme="bootstrap"].direction-up .ui-select-dropdown {
  box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.25);
}

.dropdown,
.dropup {
  position: relative;
}

.form-control {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  -webkit-transition: border-color ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.dropdown,
.dropup {
  position: relative;
}

.open>.dropdown-menu {
  display: block;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}

.tag {
  display: inline-block;
  padding: 8px 12px;
  border-radius: 24px;
  font-size: 13px;
  line-height: 16px;
  background-color: $cyber-primary;
  color: #fff;
}

.tag a {
  color: #fff;
}

[hidden] {
  display: none !important;
}

.lab-test .expansion-panel__title.layout-column {
  flex: auto !important;
  width: 100% !important;
}

.lab-test .expansion-panel {
  margin-bottom: 5px !important;
}

@font-face {
  font-family: "feather";
  font-weight: normal;
  font-style: normal;
  src: url("../../assets/fonts/feather/feather.eot?1gafuo");
  src: url("../../assets/fonts/feather/feather.eot?1gafuo#iefix") format("embedded-opentype"),
    url("../../assets/fonts/feather/feather.woff2?1gafuo") format("woff2"),
    url("../../assets/fonts/feather/feather.ttf?1gafuo") format("truetype"),
    url("../../assets/fonts/feather/feather.woff?1gafuo") format("woff"),
    url("../../assets/fonts/feather/feather.svg?1gafuo#feather") format("svg");
}

/* Icons (made with Icomoon.io) */

.icon {
  font-family: "feather";
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-transform: none;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon--arrow-left:before {
  content: "\e901";
}

.icon--menu:before {
  content: "\e903";
}

.icon--cross:before {
  content: "\e117";
}

.menu__breadcrumbs {
  font-size: 0.7rem;
  line-height: 0.7rem;
  background-color: #fafafe;
  border-radius: 20px;
  box-shadow: 0px 1px 2px rgba(70, 61, 61, 0.14);
  width: fit-content;
  margin: auto;
}

.menu__breadcrumbs a {
  font-weight: 700;
  display: inline-block;
  cursor: pointer;
  vertical-align: middle;
  letter-spacing: 1px;
  text-transform: capitalize;
  line-height: 2em;
  margin-left: 5px;
  margin-right: 5px;
  color: #706b87;
  font-family: "Open Sans", sans-serif;
}

.menu__breadcrumbs a:last-child {
  pointer-events: none;
  background-color: $cyber-primary;
  border-radius: 20px;
  padding: 6px 8px;
  color: #fff;
  margin-left: 0px;
  margin-right: 0px;
}

.menu__breadcrumbs a:hover,
.menu__breadcrumbs {
  color: $cyber-primary;
}

.menu__breadcrumbs a:not(:last-child)::after {
  content: "\e902";
  font-family: "feather";
  display: inline-block;
  padding: 0 1em;
  color: $cyber-primary;
}

.menu__breadcrumbs a:not(:last-child):hover::after,
.menu__breadcrumbs a:not(:last-child):focus::after {
  color: $cyber-primary;
}

/* Menu styles */

.menu {
  height: 100%;
  //background: #1c1d22;
}

.menu__wrap {
  position: relative;
  overflow: hidden;
  width: 100%;
  display: block;
  float: left;
  height: 100%;
  margin-top: 10px;
}

.menu__level {
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
  overflow: hidden;
  width: calc(100%);
  height: 100%;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.menu__level:focus {
  outline: none;
}

.menu__level--current {
  visibility: visible;
}

.menu__item {
  display: block;
  width: calc(100%);
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.3);
  color: #212121;
  background: #fff;
  font-family: "Open Sans", sans-serif;
  margin-bottom: 5px;
}

.menu__link {
  font-weight: 500;
  position: relative;
  display: block;
  padding: 1em 2.5em 1em 1.5em;
  color: black;
  -webkit-transition: color 0.1s;
  transition: color 0.1s;
}

.menu__link[data-submenu]::after {
  content: '';
  background: url("/assets/img/app/right-grey.png");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  right: 0;
  top: 30px;
  padding: 0em 0.5em;
  font-size: 1.8rem;
  width: 10px;
  height: 15px;
}

.menu__item.askedCategory .menu__link[data-submenu]::after {
  content: '';
  background: url("/assets/img/app/right-purple.png");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  right: 0;
  top: 30px;
  padding: 0em 0.5em;
  font-size: 1.8rem;
  width: 10px;
  height: 15px;
}

.menu__item.askedCategory:hover .menu__link[data-submenu]::after {
  content: '';
  background: url("/assets/img/app/right.png");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  right: 0;
  top: 30px;
  padding: 0em 0.5em;
  font-size: 1.8rem;
  width: 10px;
  height: 15px;
}

.menu__item-sub .menu__link[data-submenu]::after {
  content: '';
  background: url("/assets/img/app/right-grey.png");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  right: 0;
  top: 24px;
  padding: 0em 0.5em;
  font-size: 1.8rem;
  width: 10px;
  height: 15px;
}


.correct.menu__item-sub .menu__link[data-submenu]::after {
  content: '';
  background: url("/assets/img/app/right.png");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  right: 0;
  top: 24px;
  padding: 0em 0.5em;
  font-size: 1.8rem;
  width: 10px;
  height: 15px;
}

.menu__link[data-submenu]:hover::after {
  background: url("/assets/img/app/right.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.menu_back a::after {
  content: '';
  background: url("/assets/img/app/left.png");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  right: 0;
  top: 29px;
  padding: 0em 0.5em;
  font-size: 1.8rem;
  width: 10px;
  height: 15px;
}

.menu_back a:hover::after {
  background: url("/assets/img/app/left.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.special-test .menu__link[data-submenu]::after {
  top: 20px;
}

.menu__link:hover,
.menu__link:focus,
.menu__link[data-submenu]:hover::after,
.menu__link[data-submenu]:focus::after .menu_back a:hover::after,
.menu_back a:focus::after {
  color: #4caf50;
}

/*.menu__link--current::before {
	content: '\00B7';
	font-size: 1.5em;
	line-height: 0;
	position: absolute;
	top: 50%;
	left: 0.5em;
	height: 4px;
	color: #5c5edc;
}*/

stage canvas {
  @media (max-width: 767px) {
    width: 240px;
    margin-left: 32px !important;
  }
}

[class^="animate-"],
[class*=" animate-"] {
  visibility: visible;
}

.animate-outToRight .menu__item {
  -webkit-animation: outToRight 0.6s both cubic-bezier(0.7, 0, 0.3, 1);
  animation: outToRight 0.6s both cubic-bezier(0.7, 0, 0.3, 1);
}

@-webkit-keyframes outToRight {
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

@keyframes outToRight {
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

.animate-outToLeft .menu__item {
  -webkit-animation: outToLeft 0.6s both cubic-bezier(0.7, 0, 0.3, 1);
  animation: outToLeft 0.6s both cubic-bezier(0.7, 0, 0.3, 1);
}

@-webkit-keyframes outToLeft {
  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes outToLeft {
  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

.animate-inFromLeft .menu__item {
  -webkit-animation: inFromLeft 0.6s both cubic-bezier(0.7, 0, 0.3, 1);
  animation: inFromLeft 0.6s both cubic-bezier(0.7, 0, 0.3, 1);
}

@-webkit-keyframes inFromLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes inFromLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.animate-inFromRight .menu__item {
  -webkit-animation: inFromRight 0.6s both cubic-bezier(0.7, 0, 0.3, 1);
  animation: inFromRight 0.6s both cubic-bezier(0.7, 0, 0.3, 1);
}

@-webkit-keyframes inFromRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes inFromRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.menu__back {
  font-size: 1.05em;
  position: absolute;
  z-index: 100;
  top: 0;
  right: 2.25em;
  margin: 0;
  padding: 1.365em 0.65em 0 0;
  cursor: pointer;
  color: #2a2b30;
  border: none;
  background: none;
}

.menu__back--hidden {
  pointer-events: none;
  opacity: 0;
}

.menu__back:hover,
.menu__back:focus {
  color: #fff;
  outline: none;
}

/* Open and close buttons */

.action {
  position: absolute;
  display: block;
  margin: 0;
  padding: 0;
  cursor: pointer;
  border: none;
  background: none;
}

.action:focus {
  outline: none;
}

.action--open {
  font-size: 1.5em;
  top: 1em;
  left: 1em;
  display: none;
  color: #fff;
  position: fixed;
  z-index: 1000;
}

.action--close {
  font-size: 1.1em;
  top: 1.25em;
  right: 1em;
  display: none;
  color: #45464e;
}

.search {
  width: 100%;
  position: relative;
  height: 50px;
  padding: 10px;
}

.searchTerm {
  float: left;
  width: 100%;
  border: 1px solid #8CC456;
  padding: 5px;
  border-radius: 5px;
  outline: none;
  margin-left: 4px;
  color: #388e3c;
  height: 30px;
}

.searchTerm:focus {
  color: #388e3c;
}

.searchButton {
  position: absolute;
  right: 6px;
  width: 40px;
  height: 30px;
  border: 1px solid #8CC456;
  background: #8CC456;
  text-align: center;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  font-size: 20px;
}

@media screen and (max-width: 40em) {

  .action--open,
  .action--close {
    display: block;
  }

  .menu {
    z-index: 1000;
    top: 0;
    width: 100%;
    height: 100vh;
    // -webkit-transform: translate3d(-100%, 0, 0);
    // transform: translate3d(-100%, 0, 0);
    -webkit-transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
  }

  .menu--open {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

h1.chart {
  float: left;
}

button.chart {
  float: right;
}

md-dialog-content.chart {
  float: left;
}

.cdk-overlay-pane {
  @media (max-width: 790px) {
    overflow: scroll;
    max-width: 90vw;
  }

  width: auto !important;
}

textarea.chart {
  min-width: 325px;
  min-height: 300px;
}

span.chart {
  text-align: center;
  display: block;
}

.mat-cell {
  border-bottom: none !important;
}

tbody tr:nth-child(even) {
  background-color: #fff;
}

.mat-header-row {
  display: flex;
}

.mat-header-cell {
  flex: 1;
  align-self: flex-end;
  color: #706b87;
  font-size: 15px;
  padding-bottom: 10px !important;
}

.mat-cell {
  flex: 1;
  align-self: center;
  color: #706b87;
  font-size: 16px !important;
  font-family: "Open Sans", sans-serif;
}

.mat-header-row {
  @media (max-width: 767px) {
    width: 50px;
    flex-wrap: wrap;
    height: 80px;
  }
}

@media (max-width: 767px) {
  th.mat-header-cell:first-child {
    padding-left: 0px !important;
  }

  th.mat-header-cell {
    border-bottom: 0px !important;
    padding-bottom: 0px !important;
  }

  td.mat-cell:last-child {
    padding-right: 0px !important;
  }

  td.mat-cell {
    padding-left: 3px !important;
  }

  .mat-button,
  .mat-button-wrapper {
    max-width: 50px !important;
    width: 50px !important;
  }
}

.mat-row {
  display: flex;
  height: 65px !important;
  margin: 15px 0px;
  border: 1px solid #e2f2df;
  border-radius: 8px;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.04);

  @media (max-width: 767px) {
    flex-wrap: wrap;
  }

  &:hover {
    border: 2px solid #acf39d;

    td.assignment {
      font-weight: 900;
    }
  }
}

.treatment .menu__wrap {
  height: 88%;
}

.treatment.investigations .menu__wrap {
  height: 55%;
}

.go-card {
  min-width: 800px;
}

.treatment.investigation .menu__wrap {
  height: 85%;
}

.treatment .menu__level {
  overflow-y: scroll !important;
}

.treatment .menu__level.secondary-menu {
  overflow-y: hidden !important;
}

.menu__breadcrumbs {
  position: sticky !important;
  text-align: center;
  transition: all 0.5s ease;
}

.continueButton {
  position: fixed !important;
  top: 10px;
  right: 50px;
  z-index: 999;
  color: white !important;
}

.monitorBtn {
  border-radius: 20px !important;
  color: #fff !important;
}

h3 {
  width: 100%;
  color: $cyber-accent;
  font-size: 28px;
  margin-top: 20px;
}

.cyberWrap {
  padding: 20px;
}

.question {
  height: 42px;
  width: 42px;
  background-color: #388e3c;
  border-radius: 42px;
  float: right;
  text-align: center;
  color: white;
  line-height: 3em;
  top: 100px;
  margin-top: -40px;
}

.cyberCanvas {
  padding: 20px 0px 0px 0px;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.patient-history {
  background-color: #ebebeb !important;
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white !important;
  opacity: 0.85 !important;

  &:hover {
    opacity: 1 !important;
  }
}

.scroller {
  margin: 0 !important;
  padding: 0 !important;
}

.recapta {
  margin: 0 auto;
  margin-top: 20px;
}

.resultbox .mat-table,
.resultbox .labtest {
  width: 100%;
  margin-left: 5px;
  margin-right: 5px;
}

.resultbox {
  min-width: 700px;
}

.mat-tab-label.mat-tab-disabled {
  cursor: not-allowed !important;
  opacity: 0.35;
  color: inherit !important;
}

/* Text */
.text-color {
  &-primary {
    color: $cyber-primary !important;
  }
  &-secondary {
    color: $cyber-secondary !important;
  }
  &-white {
    color: #fff !important;
  }
  &-black {
    color: #000 !important;
  }
}

.text-decoration {
  &-underline {
    text-decoration: underline;
  }
}

/* Buttons */
.mat-raised-button {
  &.mat-secondary {
    background-color: $cyber-title;
    color: #fff;
    font-weight: 600 !important;

    &:hover {
      background-color: lighten($cyber-title, 20);
    }
  }

  &.mat-success {
    background-color: $cyber-primary;
    color: #fff;
    font-weight: 600 !important;

    &:hover {
      background-color: lighten($cyber-primary, 20);
    }
  }

  &.mat-dialog-cancel {
    background-color: #fff;
    color: $cyber-title;
    font-weight: 600 !important;

    &:hover {
      background-color: darken(#fff, 20);
    }
  }

  &.mat-dialog-success {
    background-color: $cyber-primary;
    color: #fff;
    font-weight: 600 !important;

    &:hover {
      background-color: lighten($cyber-primary, 20);
    }
  }
}


/* Container */

.bg {
  &-fluid {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    position: fixed;
  }

  &-color {
    &-primary {
      background-color: $cyber-primary !important;;
    }

    &-white {
      background-color: #fff !important;
    }

    &-lightgray {
      background-color: #f1f1f1 !important;;
    }

    &-danger {
      background-color: red;
    }

    &-dim {
      background-color: rgba(0, 0, 0, 0.3);
    }

    &-secondary {
      background-color: $cyber-secondary !important;
    }
  }
}

/* Dialogs */
.mat-dialog-container {
  .dialog-lg {
    width: 800px !important;
  }
  .dialog-md {
    width: 600px !important;
  }
}

.mat-dialog-content {
  margin: 0 !important;
}

/* Anchors */
a.disabled {
  pointer-events: none;
  cursor: not-allowed;
}
