// Below are the defined palettes and variables for CyberPatient.
// Please do not alter these values unless specifically instructed to do so.
// The prefix of 'cyber' was chosen in order to avoid any conflicts in the future.

// Primary Brand Colours
$cyber-accent: #77b13e;
$cyber-primary: #98d34d;
$cyber-secondary: #83729E;
$cyber-dark: #77b13e;
$cyber-green: #77BA9B;
$cyber-yellow: #ffba0c;
$cyber-title: #413C58;
// Secondary Colours
$cyber-black: #1D2F3E;
$cyber-grey: #9e9c9c;
$cyber-lightgrey: #d1d2d4;
$cyber-light: #152737;

// Button Colours
$cyber-warn: #F53A2B;
$cyber-submit: #77b13e;
$cyber-disabled: #babcce;
// // Below are the defined palettes and variables for CyberPatient.
// // Please do not alter these values unless specifically instructed to do so.
// // The prefix of 'cyber' was chosen in order to avoid any conflicts in the future.
// // Primary Brand Colours
// $cyber-accent: #8781A4;
// $cyber-primary: #98D34C;
// $cyber-dark: #413C58;
// $cyber-green: #8CC456;
// $cyber-yellow: #ffba0c;
// // Secondary Colours
// $cyber-black: #1D2F3E;
// $cyber-grey: #9e9c9c;
// $cyber-lightgrey: #d1d2d4;
// $cyber-light: #152737;
//
// // Button Colours
// $cyber-warn:  #CF4858;
// $cyber-submit: #BDF271;
// $cyber-disabled: #9e9c9c;

/**** Colors ****/
$black: #000000;
$brite-green: #adf39e;
$cautious-red: #ac4725;
$deep-green: #8dc457;
$deepest-green: #77b13e;
$grey: #d8d8d8;
$grey-dark: #9b9b9b;
$grey-smoke: #eae9eb;
$light-green: #bbdb86;
$main-green: #98d34d;
$power-indigo: #4b0cb3;
$quiet-red: #eaa791;
$royal-violet: #6458a6;
$soft-red: #d97f61;
$soft-violet: #786bc8;
$subtle-green: #a7d061;
$violet-black: #413c58;
$violet-dark: #8781a4;
$violet-darker: #706b87;
$violet-grey: #babcce;
$violet-light-grey: #efedf6;
$violet-white: #fafafe;
$warned-red: #792002;
$warning-red: #932703;
$white-smoke: #f5f5f5;
$white-storm: #f9f9f9;

/**** Fonts ****/
/** h1 **/
$h1-font-size: 22px;
$h1-color: #413C58;
$h1-line-height: 30px;
$h1-font-family: "Open Sans",
sans-serif;
$h1-font-weight: 400;
$h1-text-align: left;

@mixin h1() {
  font-size: $h1-font-size;
  color: $h1-color;
  line-height: $h1-line-height;
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  font-weight: $h1-font-weight;
  text-align: $h1-text-align;
}

/** h1-alt **/
$h1-alt-font-size: 22px;
$h1-alt-color: #8CC456;
$h1-alt-line-height: 30px;
$h1-alt-font-family: "Open Sans";
$h1-alt-font-weight: 600;
$h1-alt-text-align: left;

@mixin h1-alt() {
  font-size: $h1-alt-font-size;
  color: $h1-alt-color;
  line-height: $h1-alt-line-height;
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  font-weight: $h1-alt-font-weight;
  text-align: $h1-alt-text-align;
}

/** h2 **/
$h2-font-size: 20px;
$h2-color: #706B87;
$h2-line-height: 28px;
$h2-font-family: "Open Sans";
$h2-font-weight: 600;
$h2-text-align: left;

@mixin h2() {
  font-size: $h2-font-size;
  color: $h2-color;
  line-height: $h2-line-height;
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  font-weight: $h2-font-weight;
  text-align: $h2-text-align;
}

/** h3 **/
$h3-font-size: 16px;
$h3-color: #413C58;
$h3-text-align: left;
$h3-line-height: 22px;
$h3-font-family: "Open Sans",
sans-serif;
$h3-font-weight: 700;

@mixin h3() {
  font-size: $h3-font-size;
  color: $h3-color;
  text-align: $h3-text-align;
  line-height: $h3-line-height;
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  font-weight: $h3-font-weight;
}

/** h3-alt **/
$h3-alt-font-size: 16px;
$h3-alt-color: #8CC456;
$h3-alt-line-height: 22px;
$h3-alt-font-family: "Open Sans",
sans-serif;
$h3-alt-font-weight: 700;
$h3-alt-text-align: left;

@mixin h3-alt() {
  font-size: $h3-alt-font-size;
  color: $h3-alt-color;
  line-height: $h3-alt-line-height;
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  font-weight: $h3-alt-font-weight;
  text-align: $h3-alt-text-align;
}

/** h4-subtle **/
$h4-subtle-font-size: 16px;
$h4-subtle-color: #8781A4;
$h4-subtle-text-align: left;
$h4-subtle-line-height: 22px;
$h4-subtle-font-family: "Open Sans",
sans-serif;
$h4-subtle-font-weight: 700;

@mixin h4-subtle() {
  font-size: $h4-subtle-font-size;
  color: $h4-subtle-color;
  text-align: $h4-subtle-text-align;
  line-height: $h4-subtle-line-height;
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  font-weight: $h4-subtle-font-weight;
}

/** p1-large-paragraph **/
$p1-large-paragraph-font-size: 18px;
$p1-large-paragraph-color: #4A4A4A;
$p1-large-paragraph-line-height: 25px;
$p1-large-paragraph-font-family: "Open Sans",
sans-serif;
$p1-large-paragraph-font-weight: 400;
$p1-large-paragraph-text-align: left;

@mixin p1-large-paragraph() {
  font-size: $p1-large-paragraph-font-size;
  color: $p1-large-paragraph-color;
  line-height: $p1-large-paragraph-line-height;
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  font-weight: $p1-large-paragraph-font-weight;
  text-align: $p1-large-paragraph-text-align;
}

/** p1-large-paragraph **/
$p1-large-paragraph-font-size: 18px;
$p1-large-paragraph-color: #4A4A4A;
$p1-large-paragraph-font-family: "Open Sans",
sans-serif;
$p1-large-paragraph-font-weight: 400;
$p1-large-paragraph-text-align: left;

@mixin p1-large-paragraph() {
  font-size: $p1-large-paragraph-font-size;
  color: $p1-large-paragraph-color;
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  font-weight: $p1-large-paragraph-font-weight;
  text-align: $p1-large-paragraph-text-align;
}

/** p2-body **/
$p2-body-font-size: 16px;
$p2-body-color: #413C58;
$p2-body-font-family: "Open Sans",
sans-serif;
$p2-body-font-weight: 400;
$p2-body-text-align: left;

@mixin p2-body() {
  font-size: $p2-body-font-size;
  color: $p2-body-color;
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  font-weight: $p2-body-font-weight;
  text-align: $p2-body-text-align;
}

/** p2-bold-body **/
$p2-bold-body-font-size: 16px;
$p2-bold-body-color: #706B87;
$p2-bold-body-line-height: 22px;
$p2-bold-body-font-family: "Open Sans",
sans-serif;
$p2-bold-body-font-weight: 600;
$p2-bold-body-text-align: left;

@mixin p2-bold-body() {
  font-size: $p2-bold-body-font-size;
  color: $p2-bold-body-color;
  line-height: $p2-bold-body-line-height;
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  font-weight: $p2-bold-body-font-weight;
  text-align: $p2-bold-body-text-align;
}

/** p3 **/
$p3-font-size: 16px;
$p3-color: #706B87;
$p3-line-height: 20px;
$p3-font-family: "Open Sans",
sans-serif;
$p3-font-weight: 400;
$p3-text-align: left;

@mixin p3() {
  font-size: $p3-font-size;
  color: $p3-color;
  line-height: $p3-line-height;
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  font-weight: $p3-font-weight;
  text-align: $p3-text-align;
}

/** p4-button **/
$p4-button-font-size: 16px;
$p4-button-color: #FFFFFF;
$p4-button-line-height: 22px;
$p4-button-font-family: Open Sans Light;
$p4-button-font-weight: 300;
$p4-button-text-align: left;

@mixin p4-button() {
  font-size: $p4-button-font-size;
  color: $p4-button-color;
  line-height: $p4-button-line-height;
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  font-weight: $p4-button-font-weight;
  text-align: $p4-button-text-align;
}

/** p4-subtle **/
$p4-subtle-font-size: 14px;
$p4-subtle-color: #706B87;
$p4-subtle-line-height: 22px;
$p4-subtle-font-family: Open Sans Light;
$p4-subtle-font-weight: 300;
$p4-subtle-text-align: left;

@mixin p4-subtle() {
  font-size: $p4-subtle-font-size;
  color: $p4-subtle-color;
  line-height: $p4-subtle-line-height;
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  font-weight: $p4-subtle-font-weight;
  text-align: $p4-subtle-text-align;
}

/** p4-subtle-alt **/
$p4-subtle-alt-font-size: 16px;
$p4-subtle-alt-color: #9B9B9B;
$p4-subtle-alt-line-height: 22px;
$p4-subtle-alt-font-family: Open Sans Light;
$p4-subtle-alt-font-weight: 300;
$p4-subtle-alt-text-align: left;

@mixin p4-subtle-alt() {
  font-size: $p4-subtle-alt-font-size;
  color: $p4-subtle-alt-color;
  line-height: $p4-subtle-alt-line-height;
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  font-weight: $p4-subtle-alt-font-weight;
  text-align: $p4-subtle-alt-text-align;
}

/** p5-button **/
$p5-button-font-size: 16px;
$p5-button-color: #FFFFFF;
$p5-button-line-height: 20px;
$p5-button-font-family: "Open Sans",
sans-serif;
$p5-button-font-weight: 400;
$p5-button-text-align: left;

@mixin p5-button() {
  font-size: $p5-button-font-size;
  color: $p5-button-color;
  line-height: $p5-button-line-height;
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  font-weight: $p5-button-font-weight;
  text-align: $p5-button-text-align;
}

/** p5-style **/
$p5-style-opacity: 0.79;
$p5-style-font-size: 16px;
$p5-style-color: #D8D8D8;
$p5-style-font-family: "Open Sans",
sans-serif;
$p5-style-font-weight: 600;
$p5-style-text-align: left;

@mixin p5-style() {
  opacity: $p5-style-opacity;
  font-size: $p5-style-font-size;
  color: $p5-style-color;
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  font-weight: $p5-style-font-weight;
  text-align: $p5-style-text-align;
}

/** title-1 **/
$title-1-font-size: 28px;
$title-1-color: #413C58;
$title-1-line-height: 38px;
$title-1-font-family: "Open Sans",
sans-serif;
$title-1-font-weight: 600;
$title-1-text-align: left;

@mixin title-1() {
  font-size: $title-1-font-size;
  color: $title-1-color;
  line-height: $title-1-line-height;
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  font-weight: $title-1-font-weight;
  text-align: $title-1-text-align;
}

.p-4-subtitle {
  @include p4-subtle()
}

.p-2-body {
  @include p2-body();
}

h1 {
  @include h1();
}

h2 {
  @include h2();
}

h3 {
  @include h3();
}

h4 {
  @include h4-subtle();
}

.p-3 {
  @include p3()
}
answerparent {
  padding-left: 0px;
    padding-right: 0px;
    height: 175px;
    width: 311px;
    padding-bottom: 0px;
}
pre{
  height: 138px;    
  resize: both;
  overflow: auto;
  width: 294;
 
  border: 5px solid black;
}

